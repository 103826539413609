import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/news.scss";
import Helmet from 'react-helmet'

const News = ({ data }) => {

  return (
    <Layout>
      <Helmet bodyAttributes={{class: 'news-page'}}/>
      <SEO title="News" />
      <div className="row news mt-5">
        <div className="col-12 pl-0">
          <h1 className="mt-5">RetailStack News</h1>
        </div>
          <div className="row blog-posts">
            {data.allPrismicBlogPost.edges.map(({ node }, index) => (
              <div key={node.uid} className="col-sm-12 col-md-6 pl-md-0 blog-post left">
                  <img src={node.data.featured_image.url} alt="blog post" />
                  <div className="row pl-0">
                    <div className="col-3 col-md-4">
                      <div className={`triangle ${(index + 1) % 2 === 0 ? 'orange': ''} ${(index + 1) % 3 === 0 ? 'magenta': ''}`}></div>
                    </div>
                    <div className="col-9 col-md-8">
                      <h4><Link to={`/blog/`+ node.uid}>{node.data.title.text}</Link></h4>
                      {/* <p className="date">{node.data.publish_date}</p> */}
                      <p>{node.data.excerpt.text}</p>
                      <Link to={`/blog/`+ node.uid}>Read Full</Link>
                    </div>
                  </div>
              </div>
            ))}
          </div>
      </div>
    </Layout>
  )
}

export default News

export const query = graphql`
query {
  allPrismicBlogPost(sort: { fields: data___publish_date, order: DESC})
  {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          publish_date(formatString: "MMMM DD, YYYY")
          excerpt {
            text
          }
          featured_image {
            url
          }
        }
      }
    }
  },
  allPrismicCategory {
    edges {
      node {
        data {
          name
        }
      }
    }
  }
}
`
